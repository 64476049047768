import React, { Component } from 'react';
import { Route, Link, BrowserRouter as Router } from 'react-router-dom';
import Logo from '../components/Logo';
import IntroPage from '../components/IntroPage';
import Who from '../components/Who';
import SocialLinks from '../components/SocialLinks';
import Footer from '../components/Footer';
import './App.css';

class App extends Component {
  render() {
    return (
        <div className="container">
          <div className="inner-container">
              <Logo/>

              <Router>
                  <nav>
                      <ul>
                          <li><Link to="/">Home</Link></li>
                          <li><Link to="/about">About me</Link></li>
                      </ul>
                  </nav>

                  <div>
                      <Route exact path="/" component={IntroPage} />
                      <Route path="/about" component={Who} />
                  </div>
              </Router>


              {/*<IntroPage/>*/}
              {/*<Who />*/}
              <SocialLinks/>
              <Footer/>
          </div>
        </div>
    )
  }
}

// export default IntroPage;
export default App;
