import React, { Component } from 'react';

class IntroPage extends Component {
    render() {
        return (
            <div className="inner-page">
                <h1>Change is constant</h1>
                <p>
                    Change is constant and all organisations must go through the process of transforming to ensure survival in the current complex and competitive business environments. Shaping new strategies, introducing new products, fixing what is about to break and ensuring that regulators and ultimately shareholders are happy is the end goal of a change initiative. With this in mind, all companies should be paying attention at how change is delivered within their teams and business divisions – because if an strategic project fails to deliver, it can jeopardise the future of the entire organization.
                </p>
                <p>
                    The end goal of a Project management offices (PMO) is to ensure that all projects deliver in time, cost and within the benefits submitted in the project business case. There are many different ways to do this, however the PMO Services that are offered at Cambialar ensure that the governance and admin involved in the delivery assurance are as simple and easy to understand as possible. Using the project plan as the back bone of the change practice, funding submissions, change requests, RAID logs and project resourcing are structured around it – including the usual Project Steering and Working Groups.

                </p>
            </div>
        )
    }
}

export default IntroPage;