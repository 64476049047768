import React, { Component } from 'react';

class Footer extends Component {
    render() {
        return(
            <footer>
                <p>
                    &copy; Cambialar.com - 2019
                </p>
            </footer>
        )
    }
}

export default Footer;