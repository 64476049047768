import React, { Component } from 'react';
import newLogo from '../images/cambialar-logo.jpg';

class Logo extends Component {
    render(){
        return (
            <div className="logo-container">
                <a href="/">
                <img src={newLogo} alt="logo"/>
                </a>
            </div>
        )
    }
}

export default Logo;
