import React, { Component } from 'react';
import Portfolio from './Portfolio'

class Who extends Component {
    render() {
        return (
            <div className="inner-page">
                <h1>About me</h1>
                <p>
                    My name is Miriam Fernandez and I have 10+ years of international experience supporting the delivery of change projects as PMO. From regulatory projects in Germany and Netherlands to merging teams in Spain and managing Change Portfolios in the UK, my change knowledge can help your organisation to get the best of the change governance and delivery assurance arena – without having to invest your own time, money and resources in learning the specifics.
                </p>
                <p>
                    I specialise in financial organisations however no project is too small for me. Please, if you would like to discuss further, get in touch using my LinkedIn profile.
                </p>
                <Portfolio />
            </div>
        )
    }
}

export default Who;
