import React, { Component } from 'react';

var links = [
    {
        "linkURL": "mailto:mifermen@gmail.com",
        "linkName": "Send me an email",
        "linkIcon":  "email.svg"
    },

    {
        "linkURL": "https://www.linkedin.com/in/miriam-fernandez-pmo/",
        "linkName": "Follow me on Linkedin",
        "linkIcon":  "linkedin.svg"
    },

    {
        "linkURL": "https://www.instagram.com/cambialar/",
        "linkName": "Follow me on Instagram",
        "linkIcon":  "instagram.svg"
    }
    ];


class SocialLinks extends Component {
    render() {
        const listItems = links.map((link) =>
           <li key={link.linkURL}><a href={link.linkURL} title={link.linkName}><img src={require("../images/" + link.linkIcon)} alt={link.linkName}/></a></li>
        );
        return (
            <div>
                <ul className="social-links">
                    {listItems}
                </ul>
            </div>
        );
    }
}


export default SocialLinks;