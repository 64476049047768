import React, { Component } from 'react';
import bosLogo from '../images/bos.png';
import lloydsLogo from '../images/lloyds.jpg';
import asiLogo from '../images/asi.png';

class LogoPortfolio extends Component {
    render() {
        return (
            <div className="portfolio-images">
                <div><img src={bosLogo} alt="Bank of Scotland"/></div>
                <div><img src={lloydsLogo} alt="Lloyds Banking Group"/></div>
                <div><img src={asiLogo} alt="Aberdeen Standard invesments"/></div>
            </div>
        )
    }
}

export default LogoPortfolio;
